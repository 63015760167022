/* eslint-disable @typescript-eslint/no-namespace */
import {Severity} from "../logger"

export type ConsentCategory = "essential" | "performance" | "functional" | "advertising";
export const ESSENTIAL = "essential";
export const COOKIE_CATEGORIES = [ESSENTIAL, "performance", "functional", "advertising"] as const;

declare namespace CookieDefinitions {
    type CookieCategory = typeof COOKIE_CATEGORIES[number];

    interface ConsentCookie {
        essential: boolean,
        performance: boolean,
        functional: boolean,
        advertising: boolean,
    }

    interface CookieMetadata {
        id: string;
        version: string;
    }

    interface CompressedCookieMetadata {
        i: string;
        v: string;
    }

    interface InternalConsentCookie extends ConsentCookie, CookieMetadata {

    }

    interface CompressedConsentCookie extends CompressedCookieMetadata {
        e: 0|1,
        p: 0|1,
        f: 0|1,
        a: 0|1
    }
}
export default CookieDefinitions