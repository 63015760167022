export function update<T>(target: T, source: Partial<T>): T {
    Object.keys(source).forEach(k => {
        target[k] = source[k];
    });
    return target;
}

/**
 * Takes in a NodeList and returns an array so that regular array methods can be used on it;
 * primarily used for IE11 compatibility
 * @param {NodeList} nodeList - The list to be converted to an array
 */
export function convertToArray(nodeList: NodeList): Array<HTMLElement> {
    return Array.prototype.slice.call(nodeList);
}