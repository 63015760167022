import {act} from "../../jsx-act";
import "./button.scss"
interface ButtonProps {
    dataId?: string,
    variant: 'primary' | 'secondary'
    text: string,
    props?: {[x:string]: string},
    events?: any //TODO define better type
}

export default ({dataId, variant, text, events, props = {}} : ButtonProps) => {
    return (
        <button
            tabindex="0"
            data-id={dataId}
            type="submit"
            events={events ? events : {}}
            {...props}
            class={`awsccc-u-btn ${variant === "primary" ? 'awsccc-u-btn-primary' : 'awsccc-u-btn-secondary'}`}>
            <span>{text}</span>
        </button>
    )
};
