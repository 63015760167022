export const CONTAINER_ID = "awsccc-sb-ux-c";
export const APP_ID = "awsccc-sb-a";
export const BANNER_ID = "awsccc-cb";
export const BANNER_CUSTOMIZE_BTN_ID = "awsccc-cb-btn-customize";
export const BANNER_DECLINE_BTN_ID = "awsccc-cb-btn-decline";
export const BANNER_ACCEPT_BTN_ID = "awsccc-cb-btn-accept";
export const CUSTOMIZE_ID = "awsccc-cs";
export const ERROR_MESSAGE_MODAL_ID = "awsccc-em-modal";
export const CUSTOMIZE_CANCEL_BTN_ID = "awsccc-cs-btn-cancel";
export const CUSTOMIZE_SAVE_BTN_ID = "awsccc-cs-btn-save";
export const ERROR_MESSAGE_MODAL_DISMISS_BTN_ID = "awsccc-em-btn-dismiss";
export const TABTRAP_ID = "awsccc-cs-tabtrap";
export const ERROR_MESSAGE_MODAL_TABTRAP_ID = "awsccc-em-tabtrap";