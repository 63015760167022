import {act} from "../../jsx-act";
import { CUSTOMIZE_CANCEL_BTN_ID, ERROR_MESSAGE_MODAL_DISMISS_BTN_ID, ERROR_MESSAGE_MODAL_ID, ERROR_MESSAGE_MODAL_TABTRAP_ID } from "../../constants/ux-constants"
import { JSX } from "../../jsx-act"
import Button from "../utilities/button"
import UXDefinitions from "../../definitions/ux-definitions";

import "./error-message-modal.scss"

interface ErrorMessageModalProps {
    localizedText: UXDefinitions.ErrorMessageLocalization,
    handleDismissClick: UXDefinitions.HandleDismissClick,
    darkModeEnabled?: boolean
}


export default ({ localizedText, handleDismissClick, darkModeEnabled }: ErrorMessageModalProps) => {

    function dismissClicked() {
        handleDismissClick();
    }

    /**
     * The purpose of this function is to keep users in the modal when
     * moving focus backwards with Shift + Tab keystrokes.
     * Once outside the tab trap while tabbing backwards,
     * the focus will be on the last element 
     */
      const handleOutsideTabTrap = () => {
        document.querySelector<HTMLElement>(`button[data-id=${ERROR_MESSAGE_MODAL_DISMISS_BTN_ID}]`)!.focus({
            preventScroll: true
        })
    }

    /**
     * The purpose of the tab trap is to keep users in the
     * modal when tabbing. This prevents users from tabbing
     * to elements behind the modal. Instead tabbing from the last
     * modal element will next focus on the first modal element.
     */
    const handleLastTabTrap = () => {
        document.querySelector<HTMLElement>(`div[data-id=${ERROR_MESSAGE_MODAL_TABTRAP_ID}]`)!.focus({
            preventScroll: true
        });
    }

    const handleModalClick = (event) => {
        if (event.target.hasAttribute('data-awsccc-emm-modal-toggle')) {
            handleDismissClick()
        }
    }    

    return (
        <div data-id={ERROR_MESSAGE_MODAL_ID} style={{display: 'none'}} tabindex="0" events={{onfocus: handleOutsideTabTrap}} class={darkModeEnabled === true ? 'dark-mode-enabled' : ''}>
        <div id='awsccc-em-container' role="dialog" aria-modal="true" data-awsccc-emm-modal-toggle events={{onclick: handleModalClick}} data-id={ERROR_MESSAGE_MODAL_TABTRAP_ID} tabindex="-1">
            <div id='awsccc-em-container-inner'>
                <div id='awsccc-em-content'>
                    <div id='awsccc-em-header'>
                        <div id='awsccc-em-title'>
                            <h2>{localizedText.header}</h2>
                        </div>
                    </div>
                    <div id='awsccc-em-modalBody'>
                        <p id="awsccc-emm-paragraph">{localizedText.paragraph}</p>
                    </div>
                    <div id="awsccc-em-f-c">
                        <Button dataId={ERROR_MESSAGE_MODAL_DISMISS_BTN_ID} variant="primary" events={{onclick: dismissClicked}}
                            text={localizedText["button-dismiss"]}
                            props={{"aria-label": localizedText["button-dismiss-aria-label"]}}/>
                    </div>
                </div>
            </div>
        </div>
        <div id='awsccc-em-modalOverlay'/>
        <div data-id={ERROR_MESSAGE_MODAL_TABTRAP_ID} tabindex="-1" class="awsccc-tab-helper" events={{onfocus: handleLastTabTrap}}/>
    </div>


    )
}