const DOM_CONTENT_LOADED = "DOMContentLoaded";

/**
 * Pass a callback to this function which will execute when the DOM has been parsed and loaded but not necessarily
 * before all scripts, stylesheets, and other resources has been fetched. IE10 and below have some bugs around document
 * readiness. They are not supported in this implementation. See
 * https://w.amazon.com/bin/view/AWS/Teams/DOM/Documentation/BrowserSupport for our list of A-grade browsers. This code
 * has been heavily influenced by and is almost a straight copy of the jQuery ready() function implementation:
 * https://github.com/jquery/jquery/blob/master/src/core/ready.js.
 *
 * @param onReady this callback will be called with no parameters once the DOM has loaded.
 * @param d DO NOT USE. This is purely for making testing possible.
 * @param w DO NOT USE. This is purely for making testing possible.
 */
export default function domReady(onReady: () => void, d = document, w = window) {
    // The ready event handler and self cleanup function
    function completed() {
        d.removeEventListener(DOM_CONTENT_LOADED, completed);
        w.removeEventListener("load", completed);
        onReady();
    }

    // Catch cases where domReady is called after the browser event has already occurred. Other states are
    //  completed and interactive which indicate that the DOM is there
    if (d.readyState !== "loading") {
        // Handle it asynchronously to allow scripts the opportunity to delay ready
        w.setTimeout(onReady);
    } else {
        // Use the handy event callback
        d.addEventListener(DOM_CONTENT_LOADED, completed);

        // A fallback to window.onload, that will always work
        w.addEventListener("load", completed);
    }
}
