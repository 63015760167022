import {act} from "../../jsx-act";
import CookieDefinitions from '../../definitions/cookie-definitions'
import "./icon-checkbox.scss"

interface CheckboxProps {
    id: CookieDefinitions.CookieCategory,
    events: any, //TODO define better type when impl for renderer
    isChecked: boolean,
    localizedLabelText: string,
    localizedDescription: string,
}
export default ({ id, events, isChecked, localizedLabelText, localizedDescription}: CheckboxProps) => {
    const handleFocus = () => {
        const labelContainer = document.querySelector(`div[data-id=awsccc-u-cb-${id}-container]`) as HTMLElement
        labelContainer.classList.add("awsccc-u-cb-focused")
    }
    const handleBlur = () => {
        const labelContainer = document.querySelector(`div[data-id=awsccc-u-cb-${id}-container]`) as HTMLElement
        labelContainer.classList.remove("awsccc-u-cb-focused")
    }
    const checkedProp = (checkedProp) => checkedProp ? {checked: ''} : {}
    return (
        <div>
            <div class="awsccc-cs-s-cb-outer">
                <div class="awscc-u-cb-checkbox-container" data-id={`awsccc-u-cb-${id}-container`}>
                    <label data-id={`awsccc-u-cb-${id}-label`} class={`awsccc-u-cb-label${isChecked ? ' awsccc-u-cb-checkbox-active' : ''}`} events={events}>
                        <input
                            id={`awsccc-u-cb-${id}`}
                            class="awsccc-u-cb-input"
                            type="checkbox"
                            aria-checked={`${isChecked}`}
                            {...checkedProp(isChecked)}
                            events={{
                                onfocus: handleFocus,
                                onblur:handleBlur
                            }}
                        />
                        <span class="awsccc-cs-s-cb-hidden">{localizedDescription}</span>
                        <svg
                            viewBox="0 0 14 14"
                            aria-hidden="true"
                            focusable="false"
                            class='awscc-u-cb-checkbox'
                        >
                            <rect class="awscc-u-cb-checkbox-rect" x="0.5" y="0.5" rx="1.5" ry="1.5" width="13" height="13"/>
                            <polyline class="awscc-u-cb-checkbox-poly-line " points="2.5,7 6,10 11,3"/>
                        </svg>
                    </label>
                </div>
            </div>
            <span class="awsccc-u-cb-text" events={events}>{localizedLabelText}</span>
        </div>
    )
}